/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 /*jslint eqeq: true*/

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(function() {
          $('a[href*="#"]:not([href="#"]):not(.scrollup)').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });
        });

        $(document).ready(function() {
            var anchor = window.location.hash;
            if($(anchor).find('.full-content').length) {
              $(anchor).find('.full-content').collapse('show');
              $(anchor).find('.expand-this').text('View Less');
            }

            $('.expand-this').click(function(){
              var fullContent = $(this).closest('.expandable-content').find('.full-content');
              var container = $(this).closest('.expandable-content');
              var text = $(this).text();
              $('.expandable-content:not(' + container.attr('id') + ')').find('.full-content').collapse('hide');
              $('.expandable-content:not(' + container.attr('id') + ')').find('.expand-this').text('View More');
              fullContent.collapse('show');
              $(this).text(text == "View More" ? "View Less" : "View More");
            });
        });

        // $('#resourceDirectory').collapse();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



  // toggles the hamburger open and closed states
  var removeClass = true;

  $(".hamburger").click(function () {
      $(".hamburger").toggleClass('is-active');
      removeClass = false;
  });

  $(".side-navigation").click(function() {
      removeClass = false;
  });

  $("html").click(function () {
      if (removeClass) {
          $(".hamburger").removeClass('is-active');
      }
      removeClass = true;
  });

  // add scrollup button for the long pages
  $(document).ready(function () {
      $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
              $('.scrollup').fadeIn();
          } else {
              $('.scrollup').fadeOut();
          }
      });
      $('.scrollup').click(function () {
          $("html, body").animate({
              scrollTop: 0
          }, 600);
          return false;
      });
  });

  // footer fixing code
  $(window).bind("load", function() {
    function stickFooter() {
      var footer = $(".footer-container");
      var pos = footer.position();
      var height = $(window).height();
      height = height - pos.top;
      height = height - footer.outerHeight();
      if (height > 0) {
        footer.css({'margin-top': height + 'px'});
      }
    }
    stickFooter();
    $(window).resize(function () {
      stickFooter();
    });
  });

  // Switch escape button link
  $(document).ready(function () {
      var escape = function(){
          window.open('http://www.amazon.com', '_newtab');
          window.location.replace('http://google.com');
      };

      $('#escape').click(escape);
      $(document).keyup(function(e){
          if (e.keycode === 27) {
              escape();
          }
      });
  });

})(jQuery); // Fully reference jQuery after this point.
